<template>
  <div class="import">
    <div class="row">
      <div>Import new documents</div>
      <div></div>
      <div class="right">
        <router-link to="/archive" class="nav-link">
          <RoundButton color="orange" icon="close" />
        </router-link>
      </div>
    </div>

    <div class="row">
      <div>
        <FileUpload />
      </div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
// @ is an alias to /src
//import ArticleForm from "@/components/ArticleForm.vue";
import RoundButton from "@/components/RoundButton.vue"
import FileUpload from "@/components/FileUpload.vue"
import ArticleService from "@/services/article"

export default {
  name: "import",
  components: {
    FileUpload,
    RoundButton,
  },
  methods: {
    processInbox() {
      ArticleService.processInbox()
      Vue.notify({
        group: "alerts",
        title: "Inbox-Processing gestartet",
        text: "Die Dateien werden bearbeitet, dies kann etwas dauern",
      })
    },
  },
}
</script>
