<template>
  <div class="file-upload">
    <form id="uploadForm">
      <div class="group">
        <!--label for="message">PDF</label-->
        <input
          id="pdfs-input"
          type="file"
          accept=".pdf"
          name="pdfs[]"
          @change="onFileChange"
          multiple="multiple"
        />
      </div>
      <!--div class="group">
        <label for="medium">Medium (optional)</label>
        <fieldset>
          <label for="z">
            <input type="radio" id="z" name="medium" value="Zeitung">
            Zeitung
          </label>
          <label for="p">
            <input type="radio" id="p" name="medium" value="Prospekt">
            Prospekt
          </label>
          <label for="b">
            <input type="radio" id="b" name="medium" value="Brief">
            Brief
          </label>
        </fieldset>
      </div>
      <div class="group">
        <label for="year">Erscheinungsdatum (optional)</label>
        <input
            v-model="article.year"
            type="text"
            class="form-control"
            id="year"
            name="year"
            placeholder="Jahr" required>
        <input
            v-model="article.month"
            type="text"
            class="form-control"
            id="month"
            name="month"
            placeholder="Monat" required>
        <input
            v-model="article.day"
            type="text"
            class="form-control"
            id="day"
            name="day"
            placeholder="Tag" required>
      </div>

      <div class="group">
        <label for="title">Titel (optional)</label>
        <input
          v-model="article.title"
          type="text"
          id="title"
          name="title"
          required>
      </div>
      <div class="group">
        <label for="notes">Notizen (optional)</label>
        <textarea
          v-model="article.text"
          class="form-control"
          id="notes"
          name="notes"
          rows="3"></textarea>
      </div-->
    </form>
    <button
      @click="onUploadFile"
      class="upload-button"
      :disabled="!this.selectedFile"
    >
      Upload file
    </button>
  </div>
</template>

<script>
import Vue from "vue"

import ArticleService from "@/services/article"

export default {
  data() {
    return {
      error: false,
      article: {},
      selectedFile: "",
    }
  },
  methods: {
    onFileChange(e) {
      const selectedFile = e.target.files[0] // accessing file
      this.selectedFile = selectedFile
    },
    onUploadFile() {
      const fileInput = document.getElementById("pdfs-input")
      const files = fileInput.files

      if (parseInt(files.length) > 20) {
        alert("You can only upload a maximum of 20 files")
        return
      }

      const formData = new FormData()

      for (var i = 0; i < files.length; i++) {
        var file = files[i]

        // Check the file type.
        console.log(file.type)
        if (!file.type.match("image.*")) {
          //continue;
        }

        // Add the file to the request.
        formData.append("pdfs[]", file, file.name)
      }

      this.selectedFile = null
      document.getElementById("pdfs-input").value = ""

      ArticleService.upload(formData)
      Vue.notify({
        group: "alerts",
        title: "Import wurde angelegt",
        text: "Die Dateien werden bearbeitet, dies kann etwas dauern",
      })
    },
  },
}
</script>

<style scoped lang="scss">
.file-upload {
  padding: 20px;
  label {
    display: inline-block;
    width: 200px;
  }
}
.group {
  margin-bottom: 10px;
}
fieldset {
  display: inline-block;
}
.upload-button:disabled {
  background-color: #b3bcc4;
  cursor: no-drop;
}
</style>
